import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data: { site, markdownRemark: post }, location }) => {
  const siteTitle = site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} dark={true} title={siteTitle}>
      <div>
        <article
          className="blog-post md:w-10/12 w-full lg:w-7/12 mx-auto px-4"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2
              className={`  mb-12 article-title text-center`}
              itemProp="headline"
            >
              {post.frontmatter.title}
            </h2>
          </header>
          <section
            className="article-content mb-32"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />

          {/* <footer>
          <Bio />
        </footer> */}
        </article>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
